import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"items":_vm.data.codDeposits,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.depositedAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item.depositedAmount, 'price'))+" ")]}},{key:"item.depositedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.depositedAt))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"mx-2",attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.routeToCodDeposit(item)}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-bold-box ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }