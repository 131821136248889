<template>
  <v-data-table
    :items="data.codDeposits"
    :headers="headers"
    :items-per-page="-1"
    hide-default-footer
    fixed-header
  >
    <template #[`item.depositedAmount`]="{ item }">
      {{ formatValue(item.depositedAmount, 'price') }}
    </template>
    <template #[`item.depositedAt`]="{ item }">
      {{ formatDate(item.depositedAt) }}
    </template>
    <template #[`item.action`]="{ item }">
      <v-btn
        color="primary"
        class="mx-2"
        small
        icon
        @click="routeToCodDeposit(item)"
      >
        <v-icon color="primary">
          mdi-arrow-right-bold-box
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { formatValue } from '../../../utils/typesEnum'

const headers = [
  { text: 'Id', value: 'id' },
  { text: 'Zapłacono', value: 'depositedAmount', sortable: false },
  { text: 'Data zapłaty', value: 'depositedAt' },
  { text: 'Przejdź', value: 'action', sortable: false }
]
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    headers
  }),
  methods: {
    formatValue,
    routeToCodDeposit (item) {
      this.$router.push({ path: `/codDeposits/${item.id}` })
    },
    formatDate(date) {
      return date ? `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}` : ''
    },
  }
}
</script>
